.dealers-map {

  &__tabs-wrapper {
    position: relative;
    @include md {
      margin-right: -20px;
      margin-left: -20px; } }

  &__tabs-container {
    margin: 36px 36px 29px;
    @include md {
      margin: 25px 0 20px; }
    &.is-overflowed {
      .dealers-map__tabs {
        justify-content: flex-start; } } }


  &__tabs {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: auto;
    @include md {
      flex-wrap: nowrap;
      justify-content: flex-start; } }

  &__tab {
    display: flex;
    align-items: baseline;
    width: auto;
    position: relative;
    padding: 18px 50px;
    background-color: $G-2;
    border: 1px solid $G-3;
    @include bold;
    color: $G-5;
    cursor: pointer;
    @include md {
      padding: 6px 0 0;
      background-color: transparent;
      border: none;
      @include semi;
      color: $G-5;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $G-3; } }

    span {
      margin-left: 4px;
      font-size: 14px;
      font-weight: 300;
      @include md {
        display: none; } }

    &:hover:not(.is-active) {
      color: $red; }

    &:not(:last-child) {
      @include md {
        margin-right: 24px; } }
    &:last-child {
      @include md {
        margin-right: 20px; } }
    &:first-child {
      @include md {
        margin-left: 20px; } }

    &.is-active {
      background-color: transparent;
      border-color: transparent;
      color: #000000;
      &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: 2px;
        background-color: #000000;
        @include md {
          top: 0; } } } }

  &__container {
    display: flex;
    height: 700px;
    @include md {
      display: block;
      height: auto;
      margin-left: -20px;
      margin-right: -20px;
      width: calc(100% + 40px); } }

  &__sidebar {
    position: relative;
    width: 340px;
    flex-shrink: 0;
    margin-right: 20px;
    background-color: $G-2;
    @include md {
      width: 100%; }

    .ps__rail-y {
      width: 4px;
      border-radius: $border-radius-tiny;
      background-color: $G-3;
      transition: background-color .2s linear, width .2s ease-in-out;
      &:hover,
      &.ps--clicking {
        width: 4px; } }

    .ps__thumb-y {
      right: 0;
      border-radius: $border-radius-basic; }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
      background: linear-gradient(180deg, rgba(247, 247, 247, 0) 21.59%, #F7F7F7 100%);
      pointer-events: none; } }

  &__sidebar-inner {
    position: relative;
    max-height: 100%;
    padding: 0 16px;
    @include md {
      max-height: 510px; }
    @include mdUp {
      display: block !important; } }

  &__map-container {
    position: relative;
    width: 100%;
    @include md {
      height: 500px;
      display: none; }

    .lazy-placeholder {
      opacity: 0.5; } }

  &__map-init {
    width: 100%;
    height: 100%; }

  &__arrow {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    font-size: 24px;
    @include md {
      top: 4px;
      width: 30px;
      font-size: 16px; }

    &.swiper-button-disabled {
      display: none; }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 80px;
      height: 100%;
      pointer-events: none;
      background: linear-gradient(90deg, #FFFFFF 21.59%, rgba(255, 255, 255, 0) 99.99%, rgba(247, 247, 247, 0) 100%);
      z-index: -1;
      @include md {
        width: 45px; } }

    .icon {
      fill: $G-5; }

    &:hover {
      .icon {
        fill: $red; } }

    &--prev {
      left: 0; }

    &--next {
      right: 0;
      transform: scale(-1); } }


  &__toggle {
    display: flex;
    align-items: baseline;
    margin-top: 24px;
    background: none;
    text-transform: uppercase;
    color: $red;
    font-size: 12px;
    @include semi;

    @include mdUp {
      display: none; }

    &-icon {
      display: block;
      margin-right: 8px;
      font-size: 12px;
      .icon {
        display: block;
        fill: $red; } }

    &-hide-map {
      display: none; } }

  &.is-map-opened {
    @include md {
      .dealers-map__toggle-show-map,
      .dealers-map__sidebar {
        display: none; }
      .dealers-map__toggle-hide-map {
        display: flex; }
      .dealers-map__map-container {
        display: block; } } } }
