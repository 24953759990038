.dealers-map-address {
  $this: &;

  position: relative;
  padding: 16px 0;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid $G-3; }

  &.is-active {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -16px;

      width: calc(100% + 32px);
      height: 100%;
      background-color: $G-0; }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: -16px;

      width: 3px;
      height: 100%;
      background-color: $theme; }

    #{$this}__inner {
      &:before,
      &:after {
        content: '';
        position: absolute;
        right: 3px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg) translateY(-4px);
        width: 10px;
        height: 2px;
        background-color: $G-5; }

      &:after {
        transform: translateY(-50%) rotate(-45deg) translateY(4px); } } }

  &__inner {
    position: relative;
    display: flex;
    align-items: center; }

  &__content {
    position: relative;
    padding-right: 14px; }

  &__icon {
    position: relative;
    display: block;
    flex-shrink: 0;
    max-width: 28px;
    margin-right: 12px; }

  &__title {
    @include semi;
    padding-right: 50px; }

  &__address {
    font-size: 15px; }

  &__website {
    margin-top: 2px;
    color: $red;
    font-size: 15px; }

  &__icon-pantone {
    position: absolute;
    right: 30px;
    top: 0;

    span {
      position: absolute;
      top: calc(100% + 6px);
      left: 50%;
      z-index: 2;

      width: 109px;
      padding: 10px 20px;
      transform: translateX(-50%);

      background-color: #f9f9f9;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
      transition: opacity .25s ease-in-out, transform .25s ease-in-out;

      font-size: 13px;
      line-height: 1.54;

      opacity: 0;
      pointer-events: none;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: -7px;
        left: calc(50% - 6px);

        height: 12px;
        width: 12px;

        background-color: #f9f9f9;
        border-right: 1px solid #e0e0e0;
        border-top: 1px solid #e0e0e0;
        transform: rotate(-45deg);
        border-radius: 0 4px 0 6px; } }

    &:hover {
      span {
        opacity: 1;
        pointer-events: auto; } } } }
