.common-hero {
  margin: 40px 0;
  @include xs {
    margin: 20px 0; }

  &__inner {
    position: relative;
    min-height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: $G-0;
    text-align: center;
    @include xs {
      min-height: auto;
      color: #000000; } }

  &__content {
    padding: 20px 20px 18px;
    @include xs {
      padding: 20px 0 4px; } }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: cover;
    @include xs {
      position: static;
      min-height: 180px;
      margin: 0 -20px 0px;
      width: calc(100% + 40px); } }

  &__title {
    font-size: 44px;
    @include extrabold;
    @include xs {
      font-size: 28px;
      @include bold; } }

  &__description {
    margin: 12px auto 0;
    padding: 24px 65px 28px;
    max-width: 800px;
    background-color: rgba(186, 55, 58, 0.92);
    font-size: 20px;
    line-height: 1.6;
    @include bold;
    @include xs {
      color: $G-0;
      margin: 12px -20px 0;
      padding: 24px 20px 32px;
      font-size: 18px;
      line-height: 1.67; }

    &--no-bg {
      margin-top: -10px;
      background-color: transparent;
      @include xs {
        margin: 0;
        color: #000000;
        font-size: 15px;
        font-weight: normal; } } } }
