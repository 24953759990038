.step {
  display: flex;

  & + .step {
    margin-top: 34px; }

  &:first-child {
    .step__count {
      margin-left: 6px;
      width: 38px; } }

  &__count {
    position: relative;
    margin-right: 10px;
    width: 44px;
    flex-shrink: 0;
    margin-top: 2px;
    font-size: 48px;
    line-height: 1;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    font-weight: 300;
    color: $red;
    @include xs {
      text-align: center;
      width: 48px; } }

  &__title {
    margin-bottom: 3px;
    @include semi;
    font-size: 18px; }

  &__text {
    font-size: 15px;
    line-height: 1.5; } }
