.dealer {
  margin-top: 48px;
  @include clr;
  @include xs {
    margin-top: 20px; }

  &__descr {
    width: 320px;
    float: left;
    @include md {
      float: none;
      width: auto; }

    &.is-expanded {
      .dealer__text {
        max-height: none;
        &:before {
          display: none; } } } }

  &__title {
    margin-bottom: 16px;
    font-size: 32px;
    @include semi;
    @include xs {
      margin-bottom: 8px;
      font-size: 22px; } }

  &__address {
    margin-bottom: 2px;
    font-size: 15px; }

  &__website {
    font-size: 15px;
    color: $red; }

  &__text {
    position: relative;
    margin-top: 16px;
    font-size: 15px;
    line-height: 1.5;
    max-height: 310px;
    overflow: hidden;
    @include xs {
      margin-top: 8px;
      max-height: 315px; }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      z-index: 1;
      height: 50px;
      width: 100%;
      background: linear-gradient(0deg,#fff 10%,rgba(255,255,255,0) 100%);
      transition: opacity .3s ease;
      pointer-events: none; }

    p + p {
      margin-top: 6px; } }

  &__slider {
    width: 100%;
    margin-left: 360px;
    @include md {
      margin: 20px -20px 0;
      width: calc(100% + 40px); }

    .swiper-slide {
      width: auto;
      max-width: 680px;
      margin-right: 12px;
      @include xs {
        width: calc(100vw - 40px);
        margin-right: 0; }

      img {
        display: block;
        max-width: 100%;
        @include xs {
          width: 100%; } } } }

  &__pagination {
    position: relative;
    margin-top: 20px;
    display: none;
    @include xs {
      margin-top: 4px; }

    &.swiper-pagination-bullets {
      display: block; }

    .swiper-pagination-bullet {
      margin: 4px; } }

  &__toggle {
    position: relative;
    display: none;
    align-items: center;
    margin-top: 12px;
    font-size: 14px;
    line-height: 1;
    background-color: transparent;

    &.is-active {
      display: flex; }

    .icon {
      font-size: 5px;
      fill: $G-5;
      position: absolute;
      left: calc(100% + 5px); }

    &.is-expanded {
      .dealer__toggle-less {
        display: block; }
      .dealer__toggle-more {
        display: none; } } }

  &__toggle-more {
    color: $G-5;
    &:hover {
      color: $red; } }

  &__toggle-less {
    display: none;
    color: $G-5;
    &:hover {
      color: $red; } }

  &__pantone {
    display: flex;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 14px;
    font-size: 15px;

    img {
      width: 38px;
      flex-shrink: 0; } }

  &__pantone-text {
    margin-left: 12px; } }


.dealer-video {
  width: 658px;
  @include xs {
    width: 100%; }
  &__video-wrap {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    margin: 0 auto;
    flex: 1;
    overflow: hidden;
    background-color: $G-3;
    @include xs {
      max-width: 100%; }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0 auto; } } }
