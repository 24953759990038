.main-advantages {
  $this: &;

  position: relative;
  margin-top: 65px;
  @include r(1100) {
    margin-top: 20px; }

  &__inner {
    position: relative;
    width: 560px;
    height: 560px;
    margin: 0 auto;
    @include r(1100) {
      width: 100%;
      height: auto; } }

  &__image {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 560px;
    height: 560px;
    overflow: hidden;

    border-radius: 50%;
    background-size: cover;
    @include r(1100) {
      position: relative;
      width: 400px;
      height: 400px;
      margin: 0 auto 28px;
      @include xs {
        width: 260px;
        height: 260px; } } }

  &__logo-image {
    max-width: 200px;
    margin-bottom: 6.3em;
    @include r(1100) {
      max-width: 162px; } }

  &__main-text {
    max-width: 240px;
    margin: 0 auto 16px;
    @include semi;
    font-size: 18px;
    color: $G-0;
    text-align: center;
    @include r(1100) {
      font-size: 16px;
      line-height: 1.25; } }

  &__main-image {
    max-width: 100%;
    margin: 0 auto -5em;
    @include r(1100) {
      margin: 0 auto;
      max-width: 53%; } }

  &__item {
    width: 240px;
    font-size: 15px;

    &.is-wider {
      width: 310px;
      @include r(1100) {
        width: 100%; } }

    @include r(1100) {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 40px;
      transform: translate(0) !important;
      margin-bottom: 24px;
      padding-left: 60px;
      padding-right: 10px; }

    .icon {
      position: absolute;
      fill: $G-0;
      font-size: 44px;
      transition: fill $easing-default;
      @include r(1100) {
        position: absolute;
        top: 50%;
        left: 0;
        flex-shrink: 0;
        margin: 0 auto;
        font-size: 40px;
        transform: translate(0, -50%) !important;
        fill: #000000; }

      &-worker,
      &-pantone,
      &-nohands {
        font-size: 48px;
        @include r(1100) {
          font-size: 40px; } }

      &-van {
        font-size: 31px;
        @include r(1100) {
          font-size: 26px; } }

      &-balance,
      &-tmr {
        font-size: 40px;
        @include r(1100) {
          font-size: 32px; } }

      &-spraygun {
        font-size: 52px;
        @include r(1100) {
          font-size: 44px; } } }

    &:hover {
      position: relative;
      z-index: 9; } }

  &__tooltip {
    display: inline-block;
    position: relative;
    top: -12px;
    left: 12px;
    transition: top $easing-default;
    @include r(1100) {
      top: -10px; }

    .icon {
      display: block;
      margin-left: -6px;
      font-size: inherit;
      fill: #000000;
      transform: translate(0, 0) !important;
      @include r(1100) {
        margin-left: -9px; } }

    .tooltip {
      max-width: 420px;
      display: flex;
      top: 0;
      width: max-content;
      @include r(1100) {
        max-width: 320px; } } }

  &__text {
    position: absolute;
    width: 100%;
    height: 100%;
    @include r(1100) {
      position: static;
      padding: 0 20px; }

    p, a {
      transition: color $easing-default, border-color $easing-default, top $easing-default;
      @include rmin(1100) {
        top: 0;
        position: relative;
        .icon {
          top: 0;
          transition: top $easing-default; }
        &:hover {
          top: -6px;
          .icon {
            top: -6px; }
          & + .main-advantages__tooltip {
            top: -18px; } } } }

    p {
      display: inline; }

    a {
      position: relative;
      top: 0;
      text-decoration: none;
      color: #000000;
      border-bottom: 1px solid rgba(68, 68, 68, 0.5);
      @include r(1100) {
        position: static; }

      &:hover {
        color: $red;
        border-color: $red;

        .icon {
          fill: $red; }
        .tooltip {
          color: #000000; } } }

    &.is-right {
      transform: translateX(100%);
      @include r(1100) {
        transform: translate(0); }

      #{$this}__item {
        &:nth-child(1) {
          transform: translate(-90px, -10px);

          .icon {
            transform: translate(-151px, 51px); } }

        &:nth-child(2) {
          transform: translate(-4px, 51px);

          .icon {
            transform: translate(-137px, 14px); } }

        &:nth-child(3) {
          transform: translate(30px, 93px);

          .icon {
            transform: translate(-122px, 3px); } }

        &:nth-child(4) {
          transform: translate(29px, 155px);

          .icon {
            transform: translate(-121px, 3px); } }

        &:nth-child(5) {
          transform: translate(2px, 217px);

          .icon {
            transform: translate(-139px, -15px); } }

        &:nth-child(6) {
          transform: translate(-68px, 269px);

          .icon {
            transform: translate(-162px, -40px); } } } }


    &.is-left {
      transform: translateX(-50%);
      text-align: right;
      @include r(1100) {
        transform: translate(0);
        text-align: left; }

      #{$this}__item {
        &:nth-child(1) {
          transform: translate(131px, -10px);

          .icon {
            transform: translate(355px, 52px); } }

        &:nth-child(2) {
          transform: translate(75px, 28px);

          p {
            display: block; }

          .icon {
            left: 0;
            transform: translate(332px, 12px); } }

        &:nth-child(3) {
          transform: translate(40px, 64px);
          p {
            display: block; }

          .icon {
            left: 0;
            transform: translate(300px, 24px); } }

        &:nth-child(4) {
          transform: translate(10px, 105px);

          .icon {
            transform: translate(264px, 48px); } }

        &:nth-child(5) {
          transform: translate(14px, 160px);

          .icon {
            transform: translate(231px, 2px); } }

        &:nth-child(6) {
          transform: translate(54px, 205px);

          .icon {
            transform: translate(305px, -24px); } }

        &:nth-child(7) {
          transform: translate(134px, 251px);

          .icon {
            transform: translate(279px, -64px); } } } } } }
