@import "variables/_variables-dir";
@import "helpers/_helpers-dir";

@import "containers/common-hero";
@import "containers/main-advantages";
@import "containers/dealers-map";
@import "containers/registration";

@import "components/semi-advs";
@import "components/dealers-map-address";
@import "components/dealer";
@import "components/step";



.section--about-hero {
  margin-bottom: 52px;
  @include sm {
    margin-bottom: 10px; } }

.common-hero {
  @include sm {
    margin-bottom: 0; }
  &__content {
    margin-bottom: 56px;
    @include sm {
      margin-bottom: 0; } } }

.main-advantages {
  $this: &;

  margin-top: 44px;

  &__item {
    font-size: 18px;
    line-height: 1.56;
    @include sm {
      font-size: 15px; } }

  &__image {
    flex-direction: column; }

  &__logo-image {
    max-width: 220px;
    margin-bottom: 4.6em;
    @include r(1100) {
      max-width: 161px;
      margin-bottom: 20px; } }

  &__text {
    &.is-right {
      transform: translateX(100%);
      @include r(1100) {
        transform: translate(0); }

      #{$this}__item {
        &:nth-child(1) {
          transform: translate(-30px, 43px);
          width: 320px;
          @include r(1100) {
            width: 100%; }

          .icon {
            transform: translate(-199px, 11px);
            font-size: 48px;
            @include r(1100) {
              margin-left: 2px;
              font-size: 36px; } } }

        &:nth-child(2) {
          transform: translate(28px, 99px);
          width: 210px;
          @include r(1100) {
            width: 100%; }

          .icon {
            transform: translate(-158px, -1px);
            font-size: 52px;
            @include r(1100) {
              font-size: 40px; } } }

        &:nth-child(3) {
          transform: translate(40px, 155px);

          .icon {
            transform: translate(-131px, 5px);
            @include xs {
              font-size: 34px; } } } } }


    &.is-left {
      transform: translateX(-50%);
      text-align: right;
      @include r(1100) {
        transform: translate(0);
        text-align: left; }

      #{$this}__item {
        &:nth-child(1) {
          transform: translate(-9px, 43px);

          .icon {
            transform: translate(397px, 9px);
            font-size: 50px;
            @include r(1100) {
              margin-left: 6px;
              font-size: 32px; } } }

        &:nth-child(2) {
          transform: translate(57px, 70px);
          width: 195px;
          @include r(1100) {
            width: 100%; }

          .icon {
            transform: translate(274px, 2px);
            font-size: 48px;
            @include r(1100) {
              margin-left: 6px;
              font-size: 32px; } } }

        &:nth-child(3) {
          transform: translate(0, 126px);

          .icon {
            transform: translate(301px, 4px);
            font-size: 48px;
            @include r(1100) {
              margin-left: 6px;
              font-size: 32px; } } } } } } }




.about-description {
  max-width: 780px;
  margin: 0 auto;
  padding: 0 20px;
  text-align: center;
  font-size: 20px;
  line-height: 1.8;
  font-weight: normal;
  color: #222222;
  @include xs {
    font-size: 18px;
    line-height: 1.56; }

  &--sm {
    font-size: 18px;
    line-height: 1.78;
    @include xs {
      font-size: 15px;
      line-height: 1.6; } }

  &--mob-bg {
    @include xs {
      padding: 28px 20px;
      background-color: #f7f7f7; } }

  & + & {
    margin-top: 40px; } }

.filter-tabs + .about-description {
  margin-top: 42px;
  @include xs {
    margin-top: 26px; } }

.section--dealers-map + .about-description {
  margin-bottom: 55px; }
