.registration {
  &__title {
    margin-bottom: 34px;
    @include xs {
      margin-bottom: 24px; } }

  &__inner {
    min-height: 544px;
    display: flex;
    padding: 44px 44px 44px 91px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left bottom;
    @include md {
      display: block;
      padding: 33px 20px;
      margin-left: -20px;
      margin-right: -20px;
      background-image: none !important;
      background-color: $G-1; } }

  &__left {
    max-width: 442px;
    padding: 26px 11px 11px;
    margin-right: 120px;
    @include md {
      padding: 0;
      margin: 0 auto 37px; } }

  &__right {
    width: 100%;
    margin-top: -2px; }

  &__forms {
    max-width: 320px;
    padding-top: 4px;
    @include md {
      margin: 0 auto;
      max-width: 442px; } }

  &__step-count {
    font-size: 14px;
    color: #138547;
    @include md {
      max-width: 442px;
      margin: 0 auto; } }

  &__success {
    margin-top: 52px;
    @include xs {
      margin-top: 30px; }

    .thanks-msg {
      text-align: center;

      &__icon {
        display: block;
        margin: 0 auto; }

      &__title {
        margin-bottom: 4px;
        font-size: 20px;
        @include semi; }

      &__text {
        font-size: 15px;
        margin: 0 auto; } } } }
