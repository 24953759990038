.semi-advs {
  margin-top: 26px;

  &__decor {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    @include sm {
      max-width: 60%; }
    @include xs {
      display: none; } }

  &__list {
    display: flex;
    margin-top: 28px;
    justify-content: center;
    @include xs {
      display: block; } }

  &__image {
    height: 96px; }

  &__item {
    margin: 0 20px;
    @include sm {
      margin: 0 10px; }
    @include xs {
      margin: 0 0 40px; }

    img {
      display: block;
      margin: 0 auto;
      max-width: 100%; }

    p {
      max-width: 280px;
      margin: 0 auto;
      font-size: 18px;
      line-height: 1.56;
      text-align: center;
      @include sm {
        font-size: 16px; }
      @include xs {
        font-size: 18px; } } } }
